import { Injectable } from '@angular/core';
import {
  Observable, catchError, map, of, tap,
} from 'rxjs';
import { HttpOptionsModel } from '@amaris/lib-highway/models';
import { HttpClientService } from '@amaris/lib-highway/services';
import { ToastrService } from 'ngx-toastr';
import { Dropdown } from '../../../models/dropdown.model';
import { environment } from '../../../../environments/environment';
import { ApiEndpoints } from '../../../enums/api-endpoints.enum';
import { ApiResponse } from '../../../models/api-response.model';
import { PatientsApiService } from '../patients-api/patients-api.service';
import { DataStoreService } from '../../data-store/data-store.service';
import { MedicalHistoryExtended } from '../../../models/medical-history-extended.model';
import { DocumentApiService } from '../document-api/document-api.service';

@Injectable({
  providedIn: 'root',
})
export class AntecedentsApiService {
  constructor(
    private readonly httpClientService: HttpClientService,
    private readonly patientsApiService: PatientsApiService,
    private readonly dataStoreService: DataStoreService,
    private readonly toastrService: ToastrService,
    private readonly documentApiService: DocumentApiService,
  ) { }

  createAntecedent(patientId: string, antecedent: MedicalHistoryExtended): Observable<string> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Patients}/${patientId}${ApiEndpoints.Antecedents}`,
      withCredentials: true,
      body: antecedent,
    };
    return this.httpClientService.post<ApiResponse<string>>(options).pipe(
      tap((response: ApiResponse<string>) => {
        // TODO REMOVE TIMEOUT WHEN SIGNLALR IS IMPLEMENTED
        if (response.success) {
          this.toastrService.success('Antécédent créé avec succès');
          setTimeout(() => {
            this.patientsApiService.getPatientById(patientId).subscribe();
          }, 100);
        } else {
          this.toastrService.error('Une erreur est survenue lors de la création de l\'antécédent');
        }
      }),
      map((response: ApiResponse<string>) => response.data),
      catchError((_: any) => {
        this.toastrService.error('Une erreur est survenue lors de la création de l\'antécédent');
        return of(null);
      }),
    );
  }

  updateAntecedent(patientId: string, antecedent: MedicalHistoryExtended): Observable<ApiResponse<string>> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Patients}/${patientId}${ApiEndpoints.Antecedents}/${antecedent.id}`,
      withCredentials: true,
      body: antecedent,
    };
    return this.httpClientService.put<ApiResponse<string>>(options).pipe(
      tap((response: ApiResponse<string>) => {
        // TODO REMOVE TIMEOUT WHEN SIGNLALR IS IMPLEMENTED
        if (response.success) {
          this.toastrService.success('Antécédent mis à jour avec succès');
          setTimeout(() => {
            this.patientsApiService.getPatientById(patientId).subscribe();
          }, 100);
        } else {
          this.toastrService.error('Une erreur est survenue lors de la mise à jour de l\'antécédent');
        }
      }),
      catchError((error: any) => {
        if (error.error.error === 'the-provided-date-exceeds-todays-date') {
          this.toastrService.error('La date fournie dépasse la date d\'aujourd\'hui');
        } else {
          this.toastrService.error('Une erreur est survenue lors de la mise à jour de l\'antécédent');
        }
        return of(null);
      }),
    );
  }

  getPatientAntecedentById(patientId: string, antecedentId: string): Observable<MedicalHistoryExtended> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Patients}/${patientId + ApiEndpoints.Antecedents}/${antecedentId}`,
      withCredentials: true,
    };
    return this.httpClientService.get<ApiResponse<MedicalHistoryExtended>>(options).pipe(
      tap((result: ApiResponse<MedicalHistoryExtended>) => {
        result.data.date = new Date(result.data.date);
      }),
      map((result: ApiResponse<MedicalHistoryExtended>) => new MedicalHistoryExtended(
        result.data,
        this.documentApiService.getAllByParentid(result.data.id),
      )),
    );
  }

  deletePatientAntecedentById(patientId: string, antecedentId: string): Observable<string> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Patients}/${patientId + ApiEndpoints.Antecedents}/${antecedentId}`,
      withCredentials: true,
    };
    return this.httpClientService.delete<string>(options).pipe(
      tap((response: string) => {
        if (response) {
          this.toastrService.success('Antécédent supprimé avec succès');
          this.patientsApiService.getPatientById(patientId).subscribe();
        } else {
          this.toastrService.error('Une erreur est survenue lors de la suppression de l\'antécédent');
        }
      }),
    );
  }

  getAntecedentTypes(): Observable<Dropdown[]> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Patients + ApiEndpoints.Antecedents}`,
      withCredentials: true,
    };
    return this.httpClientService.get<ApiResponse<{id: string, label: string}[]>>(options).pipe(
      map((result: ApiResponse<{id: string, label: string}[]>) => {
        const antecedentsTypes = result.data.map((item: {id: string, label: string}) => ({ value: item.id, label: item.label }));
        this.dataStoreService.setAntecedentsTypes(antecedentsTypes);
        return antecedentsTypes;
      }),
    );
  }
}
